import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Slider from "./Slider";
import parasut from "../images/parasut.jpg";
import parasut1 from "../images/parasut1.jpg";
import parasut2 from "../images/parasut2.jpg";
import parasut3 from "../images/parasut3.jpg";
import parasut4 from "../images/parasut4.jpg";

const images = [
  {
    url: parasut,
  },
  {
    url: parasut1,
  },
  {
    url: parasut2,
  },
  {
    url: parasut3,
  },
  {
    url: parasut4,
  },
];

const YamacParasutu = () => {
  return (
    <>
      <Header />
      <div class="mt-5 text-center">
        <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
          YAMAÇ PARAŞÜTÜ
        </h1>
      </div>
      <Slider slides={images} />
      <main class="p-5">
        <div class="text-center">
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto font-bold">
            Fethiye Ölüdeniz Yamaç Paraşütü Hava Sporları Dalında Tüm Dünyanın
            Bildiği Fethiye Babadağ ‘ da Uzun Yıllardır Yapılmakta Olan Yamaç
            Paraşütü, Eşsiz Blue Logoon Manzarası ile 7’den 70′ e Herkezin
            Deneyimlemek İstediği Bir Extrem Spordur. Yılın 6 Ayı Kesintisiz
            Olmak Üzere Tüm Yıl Boyunca Hava Muhalefetine Bağlı Olarak
            Yapılabilen Bir Aktivitedir. Kendini Kanıtlatmış Profesyonel
            Dereceli Pilotlarımız ile Birlikte Haftanın Her Günü 09:00, 11:00,
            13:00, 15:00, 17:00 Saatleri Olmak Üzere Günde 5 Sorti Yapıyoruz. Bu
            Bahsetmiş Olduğumuz Saatlerin İçerisinden Size Uygun Olanı Karar
            Verdiğiniz Taktirde, Kaldığınız Yerden Araçlarla Transferi
            Sağlıyoruz ve Pilotlarla Birlikte Fethiye Ölüdeniz Babadağ Uçuş
            Noktasına Çıkıyoruz. Zirvenin 1969 mt Rakımda Olduğu Babadağ’daki
            Take Off Noktasında Hazırlığımızı Yaptıktan Sonra Dağdan Ayrılıyoruz
            ve Min 30 dk Max 45 dk Havada Kalıyoruz. Siz Havada Manzaranın
            Tadını Çıkarırken Pilotunuz o Efsane Ölüdeniz Manzarasının İnanılmaz
            Kuşbakışı Görüntülerini Son Teknoloji 360° GoPro’ lar ile Uçuş
            Boyunca Kayıt Altına Alıyor. Böylelikle Ömür Boyu Unutamayacağınız
            Görüntü ve Videolara Sahip Oluyorsunuz. Unutmayın Cesaretiniz Kadar
            Özgürsünüz.
            <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-5">
              Tur Bilgileri
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                LOKASYON - FETHİYE ÖLÜDENİZ
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                UÇUŞ SAATLERİ:
              </li>
              <li class="flex items-center">
                09:00 - 11:00 - 13:00 - 15:00 - 17:00
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                DAHİL OLANLAR:
              </li>
              <li> Transfer - Sigorta</li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                İLETİŞİM ( +90 552 390 30 20 ){" "}
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                WHATSAPP ( +90 507 539 29 61 )
              </li>
            </ul>
                  <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
                  Ücretlendirme:
                </h2>
                <ul class="list-disc list-inside font-bold text-xl ">
                  <li class="flex items-center mb-2">
                    <svg
                      class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    Yamaç Paraşütü: 2800 TL 
                  </li>
                  <p class="text-red-600 text-sm font-bold mb-3 flex items-center mt-5">
                    - Temmuz ve Ağustos Aylarında Fiyatlarımız Farklılık
                    Gösterebilir, Lütfen Bilgi Alınız.
                  </p>
                </ul>
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default YamacParasutu;
