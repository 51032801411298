import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Slider from "./Slider";
import rafting from "../images/rafting.jpg";
import rafting1 from "../images/rafting1.jpg";
import rafting2 from "../images/rafting2.jpg";
import rafting3 from "../images/rafting3.jpg";
import rafting4 from "../images/rafting4.jpg";
import rafting5 from "../images/rafting5.jpg";
import rafting6 from "../images/rafting6.jpg";
import rafting7 from "../images/rafting7.jpg";
import rafting8 from "../images/rafting8.jpg";
import rafting9 from "../images/rafting9.jpg";

const images = [
  {
    url: rafting,
  },
  {
    url: rafting1,
  },
  {
    url: rafting2,
  },
  {
    url: rafting3,
  },
  {
    url: rafting4,
  },
  {
    url: rafting5,
  },
  {
    url: rafting6,
  },
  {
    url: rafting7,
  },
  {
    url: rafting8,
  },
  {
    url: rafting9,
  },
];

const Rafting = () => {
  const [isOpen2, setIsOpen2] = useState(false);

  return (
    <>
      <Header />
      <div class="mt-5 text-center">
        <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
          RAFTİNG
        </h1>
      </div>
      <Slider slides={images} />
      <main class="p-5">
        <div class="text-center">
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto font-bold">
            Fethiye Dalaman Çayının Hırçın Sularında Adrenalin Dolu Bir Maceraya
            Hazır Mısınız? Belirttiğiniz Noktalardan Transferinizi Sağladıktan
            Sonra Rafting Bölgesine Varıyoruz ve Hep Birlikte Kahvaltı
            Yapıyoruz. Zengin Bir Kahvaltı Menüsünün Yanısıra Sınırsız Çay ve
            Kahve İçebiliyoruz. Kahvaltıdan Sonra Gerekli Ekipmanlar Olan Can
            Yeleği Kask ve Kürekler Bedenlerine Göre Konuklarımıza Verilir. Not
            : Bu Arada Bu Aktivemize Uzuv Kaybı Olanlar, Hamileler, Kol ve
            Bacaklarında Platin Olanlar Katılamaz. Ekipmanlarımızı Giydikten
            Sonra Araçlarımıza Biniyoruz ve Dağların Arasından Nehir Manzaralı
            Kısa Bir Yolculuğun Ardından Başlangıç Noktasına Varıyoruz. Türkçe
            ve İngilizce Olmak Üzere Tüm Konuklarımıza Nehirde Neler Yapmaları
            Gerektiğine Dair Detaylı Bilgi Veriyoruz. Sonrasında Bot’ları R3-R4
            Parkurunun Heyecanını Yaşamak İçin Grup Halinde Kendimizi Suya
            Bırakıyoruz. Nehirde Yaklaşık Yüzme Molaları Dahil 3 Saat Civarı
            Kalıyoruz. 14:45 Gibi Bitiş Noktasına Geliyoruz. Burada Sizi Tekrar
            Başlama Noktasına Götürecek Olan Araçlarımız Karşılıyor ve Başlangıç
            Noktasına Geri Gidiyoruz. İsteyen Misafirlerimiz Günün Yorgunluğunu
            Atmak İçin Duş Alabilir ve Üzerini Değiştirebilir. Hemen Ardından
            Öğle Yemeği Servisimiz Başlıyor ve Yemeklerimizi Yedikten Sonra
            Tekrar Sizleri Aldığımız Yere Bırakmak Üzere Yola Çıkıyoruz.
            Şimdiden Bu Heyecanı Yaşamanız Dileğiyle…
            <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-5">
              Tur Bilgileri
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                LOKASYON - FETHİYE DALAMAN ÇAYI
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                BAŞLANGIÇ - Sabah 07.30
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                DAHİL OLANLAR:
              </li>
              <li>Transfer - Sabah Kahvaltısı - Öğle Yemeği - Sigorta</li>
            </ul>
          </p>
          <div class="text-center">
            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              <div>
                <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
                  Ücretlendirme (Tek Kişi) :
                </h2>
                <ul class="list-disc list-inside font-bold text-xl ">
                  <li class="flex items-center mb-2">
                    <svg
                      class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    Rafting: 900 TL
                  </li>
                  <p class="text-red-600 text-sm font-bold mb-3 flex items-center mt-5">
                    - Temmuz ve Ağustos Aylarında Fiyatlarımız Farklılık
                    Gösterebilir, Lütfen Bilgi Alınız.
                  </p>
                </ul>
              </div>
              <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
                🌟 REZERVASYON VE DETAYLI BİLGİ İÇİN:
              </h2>
              <ul class="max-w-md space-y-1 list-inside font-bold text-xl">
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  İLETİŞİM ( +90 552 390 30 20 )
                </li>
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  İLETİŞİM ( +90 507 539 29 61 )
                </li>
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  WHATSAPP ( +90 507 539 29 61 )
                </li>
              </ul>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Rafting;
