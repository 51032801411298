import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Slider from "./Slider";
import kelebekler from "../images/kelebekler.jpg";
import kelebekler1 from "../images/kelebekler1.jpg";
import kelebekler2 from "../images/kelebekler2.jpg";
import kelebekler3 from "../images/kelebekler3.jpg";
import kelebekler4 from "../images/kelebekler4.jpg";

const images = [
  {
    url: kelebekler,
  },
  {
    url: kelebekler1,
  },
  {
    url: kelebekler2,
  },
  {
    url: kelebekler3,
  },
  {
    url: kelebekler4,
  },
];

const KelebeklerVadisi = () => {
  return (
    <>
      <Header />
      <div class="mt-5 text-center">
        <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
          ÖLÜDENİZ KELEBEKLER VADİSİ TEKNE TURU
        </h1>
      </div>
      <Slider slides={images} />
      <main class="p-5">
        <div class="text-center">
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto font-bold">
            Türkiye’nin en Meşhur Tekne Turu Olan Kelebekler Vadisi Tekne Turunu
            Tüm Yaz Boyunca Haftanın Her Günü Yapıyoruz. Ölüdeniz’in Masmavi
            Berrak Sularına ev Sahipliği Yapan Muhteşem Koylarını Gezecek
            Olduğumuz Bu Tur İçin Sabah Bulunduğunuz Yerden Transferi
            Sağlıyoruz. Dilerseniz Kendi Aracınızla da Gelebilirsiniz. Ölüdeniz
            Sahilinden Haraket Eden Teknelerimiz Sizleri Sırasıyla Kelebekler
            Vadisi, Mavi Mağara, Saint Nicholas Adası, Akvaryum Koyu, Soğuk Su
            Koyu ve Deve Plajı Olmak Üzere Bütün Bu Koyların Her Birinde 45 er
            Dakika ile 1 er Saat Arasında Yüzme Molası Veriyoruz. Yüzme
            Bilmeyenler, Yüzmesi Zayıf Olanlar ve Çocuklu Aileler İçin Bu 6
            Koyun 4 ünde Kaptanımız Paserallayı Açıyor ve Sizi Koylara Sıfır
            Yanaşarak Karaya İnmenizi Sağlıyor. Böylelikle Kıyıdanda
            Yüzebiliyorsunuz. Tekne Seçimi Olarak İster Müzikli Eğlenceli Korsan
            Teknesi İsterseniz de Sessiz Sakin Bir Tekne Tercihi Yapabilirsiniz.
            Öğle Yemeğini Teknede Aşçımızın Yaptığı Mangalda Sunuyoruz. İster
            Balık İster Tavuk Seçimi Yapabilir Bunun Yanısıra Makarna ve Salata
            Alabilirsiniz. Bu Muhteşem Koyların Hep Birlikte Tadını Çıkarıp
            Güzel Bir Gün Geçirdikten Sonra Teknemiz 17:30 da Sizi Ölüdeniz
            Sahiline İndiriyor ve Aldığımız Konaklama Noktalarına Geri
            Bırakıyoruz. Bu Koyları Gördükten Sonra Hayat Boyu Aklınızda
            Kalacak..
            <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-5">
              Tur Bilgileri
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                LOKASYON - FETHİYE ÖLÜDENİZ
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                BAŞLANGIÇ - 10:30
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                BİTİŞ - 17:30
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                DAHİL OLANLAR
              </li>
              <li> Transfer - Öğle Yemeği - Sigorta </li>
            </ul>
          </p>
          <div class="text-center">
            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              <div>
                <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
                  Ücretlendirme (Tek Kişi) :
                </h2>
                <ul class="list-disc list-inside font-bold text-xl ">
                  <li class="flex items-center mb-2">
                    <svg
                      class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    Kelebekler Vadisi Tekne Turu: 700 TL
                  </li>
                  <p class="text-red-600 text-sm font-bold mb-3 flex items-center mt-5">
                    - Temmuz ve Ağustos Aylarında Fiyatlarımız Farklılık
                    Gösterebilir, Lütfen Bilgi Alınız.
                  </p>
                </ul>
              </div>
              <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
                🌟 REZERVASYON VE DETAYLI BİLGİ İÇİN:
              </h2>
              <ul class="max-w-md space-y-1 list-inside font-bold text-xl">
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  İLETİŞİM ( +90 552 390 30 20 )
                </li>
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  İLETİŞİM ( +90 507 539 29 61 )
                </li>
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  WHATSAPP ( +90 507 539 29 61 )
                </li>
              </ul>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default KelebeklerVadisi;
