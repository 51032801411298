import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Slider from "./Slider";
import otel from "../images/otel.jpg";
import otel1 from "../images/otel1.jpg";
import otel2 from "../images/otel2.jpg";
import otel3 from "../images/otel3.jpg";
import Content4 from "./Content4";
import gemi from "../images/gemi5.jpg";
import safari from "../images/safari.jpg";
import hostel from "../images/hostel.jpg";
import hostel1 from "../images/hostel1.jpg";
import hostel2 from "../images/hostel2.jpg";
import hostel3 from "../images/hostel3.jpg";
import hostel4 from "../images/hostel4.jpg";
import hostel5 from "../images/hostel5.jpg";
import hostel6 from "../images/hostel6.jpg";
import hostel7 from "../images/hostel7.jpg";
import hostel8 from "../images/hostel8.jpg";
import hostel10 from "../images/hostel10.jpg";
import hostel11 from "../images/hostel11.jpg";
import hostel12 from "../images/hostel12.jpg";
import hostel13 from "../images/hostel13.jpg";
import paketotel from "../images/paketotel.jpg";
import paketotel1 from "../images/paketotel1.jpg";
import paketotel2 from "../images/paketotel2.jpg";
import paketotel3 from "../images/paketotel3.jpg";
import paketotel4 from "../images/paketotel4.jpg";
import paketotel5 from "../images/paketotel5.jpg";
import paketotel6 from "../images/paketotel6.jpg";
import paketotel7 from "../images/paketotel7.jpg";
import paketotel8 from "../images/paketotel8.jpg";
import paketotel10 from "../images/paketotel10.jpg";
import paketotel11 from "../images/paketotel11.jpg";
import paketotel12 from "../images/paketotel12.jpg";
import Content3 from "./Content3";

const images = [
  {
    url: hostel,
  },
  {
    url: gemi,
  },
  {
    url: hostel1,
  },
  {
    url: safari,
  },
  {
    url: hostel2,
  },
  {
    url: hostel3,
  },
  {
    url: hostel4,
  },
  {
    url: hostel5,
  },
  {
    url: hostel6,
  },

  {
    url: hostel7,
  },
  {
    url: hostel8,
  },
  {
    url: hostel10,
  },
  {
    url: hostel11,
  },
  {
    url: hostel12,
  },
  {
    url: hostel13,
  },
  {
    url: paketotel,
  },
  {
    url: paketotel1,
  },
  {
    url: paketotel2,
  },
  {
    url: paketotel3,
  },
  {
    url: paketotel4,
  },
  {
    url: paketotel5,
  },
  {
    url: paketotel7,
  },
  {
    url: paketotel8,
  },
  {
    url: paketotel10,
  },
  {
    url: paketotel11,
  },
  {
    url: paketotel12,
  },
];

const Otel4 = () => {
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  return (
    <>
      <Header />
      <div class="mt-5 text-center">
        <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-2">
          3 GECE - 4 GÜN - 3 AKTİVİTE
        </h1>
      </div>
      <Slider slides={images} />
      <Content3 />
      <main class="p-5">
        <div class="text-center">
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
            <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 -mt-20">
              🌟 REZERVASYON VE DETAYLI BİLGİ İÇİN:
            </h2>
            <ul class="max-w-md space-y-1 list-inside dark:text-gray-400 font-bold text-xl">
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                İLETİŞİM ( +90 552 390 30 20 )
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                İLETİŞİM ( +90 507 539 29 61 )
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                WHATSAPP ( +90 507 539 29 61 )
              </li>
            </ul>
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Otel4;
