import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Slider from "./Slider";
import tekne5 from "../images/tekne5.jpg";
import tekne1 from "../images/tekne1.jpg";
import tekne2 from "../images/tekne2.jpg";
import tekne3 from "../images/tekne3.jpg";
import tekne4 from "../images/tekne4.jpg";

const images = [
  {
    url: tekne1,
  },
  {
    url: tekne2,
  },
  {
    url: tekne3,
  },
  {
    url: tekne4,
  },
  {
    url: tekne5,
  },
];
const TekneTuru = () => {
  return (
    <>
      <Header />
      <div class="mt-5 text-center">
        <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
          FETHİYE ÖZEL TEKNE TURU
        </h1>
      </div>
      <Slider slides={images} />
      <main class="p-5">
        <div class="text-center">
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto font-bold">
            Fethiye Özel Tekne Turu ile rotayı sizin belirlediğiniz bu turda
            istediğiniz durakta fazla zaman geçirip, istediğiniz durağa
            uğramayabilirsiniz. Bu teknelerde ister ailenizle yalnız siz olarak
            keyfini çıkarabilir yada doğum günü ve benzeri özel günlerinizde de
            bu seçeneği değerlendirebilirsiniz. Ayrıca şirket bünyemizde helal
            konsept tekne turu seçeneklerimiz de vardır.Detaylı bilgileri
            ofisimizdeki tur danışmanı arkadaşlarımızdan alabilirsiniz.
            Yemeklerimiz siz konuklarımıza mangalda pişen balık ve tavuk ile
            açık büfe olarak mezeler eşliğinde sunulmaktadır. Saat 09:30 da
            Otelinizden servis ile alınarak teknemizin çıkış yapacak olduğu
            limana ulaşılır. Güzel bir günün ardından turumuz tamamlanır ve saat
            17:00 gibi limana dönüş yapılır. Siz konuklarımızın oteline
            bırakılmasıyla özel tekne turu sonlanır. Fethiye Özel Tekne Turu
            Programı İster ölüdeniz limanı çıkışlı, isterseniz Fethiye limanı
            çıkışlı bu teknelerimiz ayrı güzergahları izlemektedir. Ölüdeniz’den
            çıkan turumuz, kelebekler vadisi, mavi mağara, soğuk su, deve plajı,
            nicholas adasını ziyaret etmektedir. Fethiye limanından çıkan
            teknemiz ise, daha sakin bir rota olan kızıl ada, samanlık koyu,
            boncuklu koyu, akvaryum koyu, tarzan koyunu ziyaret etmektedir. Size
            Özel Tekne Turunda teknemizde balık tutma ekipmanları, can
            yelekleri, güneş yatakları ve gölgelik alan konuklarımızın hizmetine
            sunulmaktadır. Fethiye özel tekne kiralama fiyatlarına, transfer,
            açık büfe yemek, sigorta bedeli dahildir. Kalabalıkdan uzak, masmavi
            denizlerde bir tekne turu sizi bekliyor. Özel Tekne Turu İçin
            Öneriler Gün boyu sürecek bu turun daha konforlu geçmesi için
            yanınıza bazı kişisel eşyalar almanızı tavsiye ediyoruz. Bunlar;
            deniz ayakkabısı, havlu, güneş kremi, şapka, güneş gözlüğü, mayo,
            yedek kıyafet, fotoğraf makinesi, öğle yemeği dışında alınacak
            içecekler için bir miktar nakit para alınmalıdır. Tur rotası boyunca
            , güneşleme zamanı iyi ayarlanmalı, bazen gölge alanda da vakit
            geçirmelisiniz. Mola verilen duraklarda derinlik hakkında bilgi
            almadan denize girmemelisiniz.
            <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-5">
              Tur Bilgileri
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                LOKASYON - FETHİYE
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                BAŞLANGIÇ - Siz belirliyorsunuz
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                BİTİŞ - Siz belirliyorsunuz
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                DAHİL OLANLAR
              </li>
              <li>Transfer - Açık Büfe Öğle Yemeği - Sigorta</li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                İLETİŞİM ( +90 552 390 30 20 ){" "}
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                WHATSAPP ( +90 507 539 29 61 )
              </li>
            </ul>
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default TekneTuru;
