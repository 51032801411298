import React from "react";

function ScrollLink({ targetId, children, active }) {
  function handleClick(e) {
    e.preventDefault();

    const targetId = e.currentTarget.getAttribute("href").slice(1);
    const targetElement = document.getElementById(targetId);

    const originalTop =
      targetElement.getBoundingClientRect().top + window.pageYOffset;
    const headerOffset = document.querySelector("header").offsetHeight;
    const top = originalTop - headerOffset;

    window.scrollTo({
      top,
      behavior: "smooth",
    });
  }

  return (
    <a
      href={`#${targetId}`}
      onClick={handleClick}
      className={
        active
          ? "mr-5  font-semibold text-m text-red-800 px-4 py-2.5 text-center inline-flex items-center"
          : "mr-5 hover:text-red-800 font-semibold text-m text-gray-800 px-4 py-2.5 text-center inline-flex items-center"
      }
    >
      {children}
    </a>
  );
}

export default ScrollLink;
