import React, { useState, useEffect, useRef } from "react";
import shiptour from "../images/tekne2.jpg";
import safari from "../images/safari.jpg";
import diving from "../images/diving1.jpg";
import horse from "../images/atturu8.jpg";
import atv from "../images/atv1.jpg";

const Content5 = () => {
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);

  return (
    <div class="container my-24 px-6 mx-auto">
      <section class="mb-32 -mt-20 text-gray-800">
        <h1 class="font-bold text-3xl mb-5">
          {" "}
          3 GECE - 4 GÜN - 2 AKTİVİTE (KAMP)
        </h1>
        <p class="uppercase text-red-600 font-bold mb-2 flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            class="w-4 h-4 mr-2"
          >
            <path
              fill="currentColor"
              d="M216 23.86c0-23.8-30.65-32.77-44.15-13.04C48 191.85 224 200 224 288c0 35.63-29.11 64.46-64.85 63.99-35.17-.45-63.15-29.77-63.15-64.94v-85.51c0-21.7-26.47-32.23-41.43-16.5C27.8 213.16 0 261.33 0 320c0 105.87 86.13 192 192 192s192-86.13 192-192c0-170.29-168-193-168-296.14z"
            />
          </svg>
          PAKET TUR ÖZETİ
        </p>
        <ul class="list-disc list-inside font-bold mb-6">
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Saklıkent Jeep Safari
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Tüplü Dalış
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Aktivite Sigortası
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Fethiye'de 3 Gece 4 Gün Konaklama
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Sabah Kahvaltısı
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Aktivite Günlerinde Öğle Yemeği(2. ve 3.gün)
          </li>
        </ul>

        <p class="mb-6"></p>
        <p class="mb-6 font-bold">
          🌟 Nisan, Mayıs, Haziran, Temmuz, Ağustos, Eylül Ayları içinde, Kendi
          Tarihinizi Kendiniz Belirleyin, Mutlaka en az 5 Gün Öncesinden
          Yerininizi Ayırtmayı Unutmayın.
        </p>

        <p class="mb-6 font-bold">
          <strong>- Rüya gibi Camping Programımızda Neler Var ?</strong>
        </p>

        <p class="mb-6 font-bold">
          🌟 Jeep Safari İle Eğlenceyi Doruğunda Yaşayın ve Çocuklar gibi
          Eğlenin
        </p>
        <p class="mb-6 font-bold">
          🌟 Tüplü Dalış İle Su Altı Dünyasını Keşfet
        </p>

        <p class="mb-6 font-bold">
          🌟 Dünyanın Her Yerinden İnsanlarla Tanışın ve Arkadaş Olun
        </p>
        <p class="mb-6 font-bold">
          🌟 Unutulmaz Bir Tatilin Ardından Evinize Ömür Boyu Unutamayacağınız
          Anılar İle Dönün
        </p>

        <p class="note note-light border-l-4 border-gray-800 rounded p-2 mb-3">
          <strong>Camping Bilgileri</strong>
        </p>
        <p class="mb-6 font-bold">
          Fethiye Ölüdeniz Mevkinde Yer Alan Kamp Alanımız 10.000m2 alan Üzerine
          Kurulu Glambing Çadır Alanı, Karavan Park Alanı, Yoga İnziva Alanı ,
          Bungolovları, Aileler İçin Havuzlu Bungolovları,Restoran ve Ortak
          Kullanım Alanları İle Hizmetinizdeyiz. Tercihe Göre Kendi Çadırınızı
          Yanınızda Getirebilir Dilerseniz Tesisimizde Hazır Bulunan Çadır,
          Glambing, Bungolovlarda Konaklama Yapabilirsiniz. Kahvaltı ve Yemekler
          Günün Her Saati Müşterilerimiz İçin Uygun Fiyattan Sunulmaktadır.
          Havuzlu Bungolovlarımızda Aileler İçin Duş Wc İçindedir
        </p>

        <p></p>

        <p class="note note-light border-l-4 border-gray-800 rounded p-2 mb-6 border">
          <button
            class={
              !isOpen2
                ? "mr-5 hover:text-cyan-800 font-semibold text-m text-cyan-800 px-4 py-2.5 text-center inline-flex items-center"
                : "mr-5 hover:text-red-800 font-semibold text-m text-red-800 px-4 py-2.5 text-center inline-flex items-center"
            }
            type="button"
            data-dropdown-toggle="dropdown"
            onClick={() => setIsOpen2(!isOpen2)}
          >
            <strong>1. Gün - Kampa Yerleşme ve Serbest Zaman</strong>
            {isOpen2 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
                />
              </svg>
            ) : (
              <svg
                class="w-4 h-4 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            )}
          </button>
          {isOpen2 && (
            <p class="mb-6 font-bold">
              🌟 Bugün tatilinizin ilk günü kamp alanına yerleştikten sonra
              etrafı keşfedebilirsiniz.
            </p>
          )}
        </p>

        <p class="note note-light border-l-4 border-gray-800 rounded p-2 mb-6 border">
          <button
            class={
              !isOpen3
                ? "mr-5 hover:text-cyan-800 font-semibold text-m text-cyan-800 px-4 py-2.5 text-center inline-flex items-center"
                : "mr-5 hover:text-red-800 font-semibold text-m text-red-800 px-4 py-2.5 text-center inline-flex items-center"
            }
            type="button"
            data-dropdown-toggle="dropdown"
            onClick={() => setIsOpen3(!isOpen3)}
          >
            <strong>2. Gün - Saklıkent Jeep Safari</strong>
            {isOpen3 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
                />
              </svg>
            ) : (
              <svg
                class="w-4 h-4 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            )}
          </button>
          {isOpen3 && (
            <>
              <img
                src={safari}
                class="w-full shadow-lg rounded-lg mb-6 mt-4"
                style={{ width: "700px", height: "400px" }}
              />
              <p class="mb-6 font-bold">
                Üstü açık yada yarı açık jeep’lerimiz ile safari ve eğlence
                turuna katılmaya ne dersiniz? sabah otel transferi ardından
                buluşma noktamızda toplanıp tüm araçlarımızla aynı anda yola
                çıkıyoruz. çam ormanları arasındaki muhteşem yollardan geçerek
                saklıkent kanyonuna doğru ilerliyoruz. doğa içinde, eğlence ve
                adrenalin dolu bir tur olan jeep safari turumuz her yaşa
                uygundur. ilk durağımız tarihi bir likya kenti olan tlos. burada
                gezerek tarih hakkında bilgi alıyor, amfi tiyatro, kaya
                mezarları ve diğer yerleşim alanlarını görüyoruz. ardından
                alabalığı ile meşhur olan insan yapımı bir cennet yakapark’a
                götürüyoruz. burada lezzetli bir öğlen yemeği yiyoruz ve
                alabalık çiftliğini ziyaret edip soğuk su kaynaklarını ziyaret
                ediyoruz. ardından saklıkent kanyonuna ilerliyoruz. 18km
                uzunluğu ve 300mt’yi bulan derinliği ile avrupanın 2. büyük
                kanyonu olan saklıkent kanyonu doğası ve görüntüsü ile sizleri
                büyüleyecektir. saklıkent gezimizin ardında rafting
                yapabilirsiniz. tam anlamı ile bir rafting olmasada çay üzerinde
                simitlerle yapılan bu rafting çeşidi emin olunki çok
                eğlencelidir. raftingin son noktası ile çamur banyosu oluyor.
                cilde iyi geldiği söylenen kükürt içeren bir çamur ile
                gençleşebilir ve cildinizi parlatabilirsiniz. güzergah:
                tlos,yakapark.saklıkent,kanyon,şelâle,çamur banyosu.
              </p>
            </>
          )}
        </p>

        <p class="note note-light border-l-4 border-gray-800 rounded p-2 mb-6 border">
          <button
            class={
              !isOpen4
                ? "mr-5 hover:text-cyan-800 font-semibold text-m text-cyan-800 px-4 py-2.5 text-center inline-flex items-center"
                : "mr-5 hover:text-red-800 font-semibold text-m text-red-800 px-4 py-2.5 text-center inline-flex items-center"
            }
            type="button"
            data-dropdown-toggle="dropdown"
            onClick={() => setIsOpen4(!isOpen4)}
          >
            <strong>3. Gün - Tüplü Dalış</strong>
            {isOpen4 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
                />
              </svg>
            ) : (
              <svg
                class="w-4 h-4 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            )}
          </button>
          {isOpen4 && (
            <>
              <img
                src={diving}
                class="w-full shadow-lg rounded-lg mb-6 mt-4"
                style={{ width: "700px", height: "400px" }}
              />
              <p class="mb-6 font-bold">
                Denizler altındaki güzellikleri görebilmek için Kızıl Deniz’e
                kadar gitmenize gerek yok. Ölüdeniz / Fethiye’de Tüplü Dalış
                turumuz’da hiç dalış yapmamış olanlar, yeni başlayanlar ve
                deneyimli dalıcılar Ölüdeniz’in sualtı güzelliklerini görme
                şansına sahip olabilirler. Su altı dalış eğitimi ekibimiz PADI –
                CMAS – BSAC kurslarının yanısıra dalış süresince sualtı
                video/fotoğraf çekimi servisleri de sunmaktadır. Tur programı
                şöyledir. Dalış hocamızın verdiği brifingten sonra tüm
                misafirlerimiz dalışa hazırlanıyor ve ilk dalışımızı Dalyan
                Koyu‘nda 5 metre derinlikte 25 dakika boyunca su altında
                gerçekleştiriyoruz, sonrasında biraz dinlenip öğle yemeğimizi
                yedikten sonra bu defa Fethiye’nin en popüler koyu olan Tarzan
                Koyu ‘nda 30 dakika daha dalış yapıp dönüş hazırlıklarına
                başlıyoruz.
              </p>
            </>
          )}
        </p>
        <p class="note note-light border-l-4 border-gray-800 rounded p-2 mb-6 border">
          <button
            class={
              !isOpen8
                ? "mr-5 hover:text-cyan-800 font-semibold text-m text-cyan-800 px-4 py-2.5 text-center inline-flex items-center"
                : "mr-5 hover:text-red-800 font-semibold text-m text-red-800 px-4 py-2.5 text-center inline-flex items-center"
            }
            type="button"
            data-dropdown-toggle="dropdown"
            onClick={() => setIsOpen8(!isOpen8)}
          >
            <strong>4. Gün - Veda Zamanı</strong>
            {isOpen8 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
                />
              </svg>
            ) : (
              <svg
                class="w-4 h-4 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            )}
          </button>
          {isOpen8 && (
            <p class="mb-6 font-bold">
              Camping'den çıkış saatimiz 12:00'dir. rüyagibi bir tatil için biz
              hep buradayız ve yanınızdayız tekrar görüşmek dileğiyle.
            </p>
          )}
        </p>

        <p></p>

        <p class="text-red-600 font-bold mb-3 flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            class="w-4 h-4 mr-2"
          >
            <path
              fill="currentColor"
              d="M216 23.86c0-23.8-30.65-32.77-44.15-13.04C48 191.85 224 200 224 288c0 35.63-29.11 64.46-64.85 63.99-35.17-.45-63.15-29.77-63.15-64.94v-85.51c0-21.7-26.47-32.23-41.43-16.5C27.8 213.16 0 261.33 0 320c0 105.87 86.13 192 192 192s192-86.13 192-192c0-170.29-168-193-168-296.14z"
            />
          </svg>
          ÖNEMLİ BİLGİLER
        </p>
        <ul class="list-disc list-inside font-bold">
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Rüyagibi Paket Programımıza 18 yaş ve üzeri Herkes Katılabilir.
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Bütün Camping Paket Tur Programlarımızın Başlangıç Ve Bitiş Tarihini
            Siz Belirliyorsunuz.
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Katılacağınız Aktivitelerde Yüzme Bilmenize Gerek Yok. Profesyonel
            Ekibimizin Gözü Hep Üstünüzde Olacaktır.
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Ön Ödeme, Havale/Eft Yöntemi ile Yapılmaktadır.
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Kalan Bakiye Kampa Giriş Yapıldığı Gün Gece 00:00’a Kadar ister
            Nakit İster Havale/Eft Yöntemi İle Yapabilirsiniz.
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Paket Tur Programında Katılım Yapacağınız Tarihten en az 5 Gün Önce
            Rezervasyon Yapılması Gerekmektedir.
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Aktivitelerimizde Yaş ve Kilo Sınırı Yoktur.
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Paket Programına Gelirken Mayo,Havlu, Şort gibi Eşyalarınızdan Hariç
            Aktivitelerde Önlem Amaçlı Telefon, Para gibi Değerli Eşyalarınız
            için Su Geçirmez Çanta ve Telefon Kılıfı Bulundurmalısınız.
          </li>
          <li class="flex items-center mb-2">
            <svg
              class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Paket Programında Bulunan Mevcut Aktiviteleri Dilerseniz Diğer
            Günübirlik Aktiviteler İle Değiştirebilirsiniz.
          </li>
        </ul>
        <div>
          <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
            3 GECE - 4 GÜN - 2 AKTİVİTE KAMP FİYATLARI ( TEK KİŞİ ) :
          </h2>
          <ul class="list-disc list-inside font-bold text-xl ">
            <li class="flex items-center mb-2">
              <svg
                class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Kendi Çadırı: 2250 TL
            </li>
            <li class="flex items-center mb-2">
              <svg
                class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Tesis Çadırı: 2900 TL
            </li>
            <li class="flex items-center mb-2">
              <svg
                class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Glambing: 3200 TL
            </li>
            <li class="flex items-center mb-2">
              <svg
                class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Bungolov: 4400 TL
            </li>
            <li class="flex items-center mb-2">
              <svg
                class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Havuzlu Bungolov: 5000 TL
            </li>
            <li class="flex items-center mb-2">
              <svg
                class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              Kabin House: 3950 TL
            </li>
            <p class="text-red-600 font-bold mb-3 flex items-center mt-5">
              - Temmuz ve Ağustos Aylarında Fiyatlarımız +500 TL Farklılık
              Göstermektedir.
            </p>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Content5;
