import React, { useState } from "react";
import logo from "../images/logo.svg";
import logoo from "../images/logooo.png";

const Headerr = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  const [changeHeader, setChangeHeader] = useState(false);

  //header change function
  const onChangeHeader = () => {
    if (window.scrollY >= 100) {
      setChangeHeader(true);
    } else {
      setChangeHeader(false);
    }
  };

  //change header by scrolling
  window.addEventListener("scroll", onChangeHeader);

  /*
  {
        changeHeader
          ? "bg-white fixed z-50 top-0 left-0 w-full shadow-md transition duration-500"
          : "text-white-600 body-font"
   }
  */

  return (
    <header className="text-white-900 body-font">
      <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a
          href="/"
          class="flex title-font font-medium items-center text-white-900 mb-4 md:mb-0"
        >
          <img src={logoo} width={100} height={70} alt="Logo" />
          <span class="ml-3 text-xl text-white-900"></span>
        </a>
        <nav class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-black-400	flex flex-wrap items-center text-base justify-center">
          <button
            class={
              !isOpen1
                ? "mr-5 hover:text-white-800 font-semibold text-m text-white px-4 py-2.5 text-center inline-flex items-center"
                : "mr-5 hover:text-white-800 font-semibold text-m text-white px-4 py-2.5 text-center inline-flex items-center"
            }
            type="button"
            data-dropdown-toggle="dropdown"
            onClick={() => setIsOpen1(!isOpen1)}
          >
            Rüya Gibi Paket Turlar
            {isOpen1 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
                />
              </svg>
            ) : (
              <svg
                class="w-4 h-4 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            )}
          </button>
          <div
            class={
              !isOpen1
                ? "hidden bg-white text-base z-50 list-none divide-y divide-black-100 rounded shadow my-4"
                : "bg-white text-base z-50 list-none divide-y divide-black-100 rounded shadow my-4"
            }
          >
            <div
              id="mega-menu-full-dropdown"
              class="mt-1 bg-white border-black-200 shadow-sm border-y dark:bg-black-800 dark:border-black-600"
            >
              <div class="grid max-w-screen-xl px-4 py-5 mx-auto text-white-900 dark:text-white sm:grid-cols-6 md:grid-cols-5 md:px-6">
                <ul aria-labelledby="mega-menu-full-dropdown-button">
                  <li class="border mb-5">
                    <button
                      class={
                        !isOpen3
                          ? "mr-5 hover:text-white-800 font-semibold text-m text-white-800 px-4 py-2.5 text-center inline-flex items-center"
                          : "mr-5 hover:text-white-800 font-semibold text-m text-white-800 px-4 py-2.5 text-center inline-flex items-center"
                      }
                      type="button"
                      data-dropdown-toggle="dropdown"
                      onClick={() => setIsOpen3(!isOpen3)}
                    >
                      Otel Konaklamalı
                      {isOpen3 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
                          />
                        </svg>
                      ) : (
                        <svg
                          class="w-4 h-4 ml-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          ></path>
                        </svg>
                      )}
                    </button>
                    <div
                      class={
                        !isOpen3
                          ? "hidden bg-white text-base z-50 list-none divide-y divide-black-100 rounded shadow my-4"
                          : "bg-white text-base z-50 list-none divide-y divide-black-100 rounded shadow my-4"
                      }
                    >
                      <div
                        id="mega-menu-full-dropdown"
                        class="mt-1 bg-white border-black-200 shadow-sm border-y dark:bg-black-800 dark:border-black-600"
                      >
                        <a href="/otel4gun3gece">
                          <div class="grid max-w-screen-xl px-4 py-5 mx-auto text-white-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-black-50">
                            <ul aria-labelledby="mega-menu-full-dropdown-button">
                              <li class="font-semibold ">
                                3 Gece 4 Gün 2Aktivite
                              </li>
                            </ul>
                          </div>
                        </a>
                        <a href="/otel4gun3gece3aktivite">
                          <div class="grid max-w-screen-xl border px-4 py-5 mx-auto text-white-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-black-50">
                            <ul aria-labelledby="mega-menu-full-dropdown-button">
                              <li class="font-semibold ">
                                3 Gece 4 Gün 3Aktivite
                              </li>
                            </ul>
                          </div>
                        </a>
                        <a href="/otel7gun6gece">
                          <div class="grid max-w-screen-xl border px-4 py-5 mx-auto text-white-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-black-50">
                            <ul aria-labelledby="mega-menu-full-dropdown-button">
                              <li class="font-semibold">
                                6 Gece 7 Gün 5Aktivite
                              </li>
                            </ul>
                          </div>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li class="border mb-5">
                    <button
                      class={
                        !isOpen4
                          ? "mr-5 hover:text-white-800 font-semibold text-m text-white-800 px-4 py-2.5 text-center inline-flex items-center"
                          : "mr-5 hover:text-white-800 font-semibold text-m text-white-800 px-4 py-2.5 text-center inline-flex items-center"
                      }
                      type="button"
                      data-dropdown-toggle="dropdown"
                      onClick={() => setIsOpen4(!isOpen4)}
                    >
                      Kamp Konaklamalı
                      {isOpen4 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
                          />
                        </svg>
                      ) : (
                        <svg
                          class="w-4 h-4 ml-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          ></path>
                        </svg>
                      )}
                    </button>
                    <div
                      class={
                        !isOpen4
                          ? "hidden bg-white text-base z-50 list-none divide-y divide-black-100 rounded shadow my-4"
                          : "bg-white text-base z-50 list-none divide-y divide-black-100 rounded shadow my-4"
                      }
                    >
                      <div
                        id="mega-menu-full-dropdown"
                        class="mt-1 bg-white border-black-200 shadow-sm border-y dark:bg-black-800 dark:border-black-600"
                      >
                        <a href="/camping4gun3gece">
                          <div class="grid max-w-screen-xl px-4 py-5 mx-auto text-white-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-black-50">
                            <ul aria-labelledby="mega-menu-full-dropdown-button">
                              <li class="font-semibold">
                                3 Gece 4 Gün 2Aktivite
                              </li>
                            </ul>
                          </div>
                        </a>
                        <a href="/camping5gun4gece">
                          <div class="grid max-w-screen-xl border px-4 py-5 mx-auto text-white-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-black-50">
                            <ul aria-labelledby="mega-menu-full-dropdown-button">
                              <li class="font-semibold">
                                4 Gece 5 Gün 3Aktivite
                              </li>
                            </ul>
                          </div>
                        </a>
                        <a href="/camping7gun6gece">
                          <div class="grid max-w-screen-xl border px-4 py-5 mx-auto text-white-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-black-50">
                            <ul aria-labelledby="mega-menu-full-dropdown-button">
                              <li class="font-semibold">
                                6 Gece 7 Gün 5Aktivite
                              </li>
                            </ul>
                          </div>
                        </a>
                      </div>
                    </div>
                  </li>
                  {/* 
                  <li class="border">
                    <button
                      class={
                        !isOpen7
                          ? "mr-5 hover:text-white-800 font-semibold text-m text-white-800 px-4 py-2.5 text-center inline-flex items-center"
                          : "mr-5 hover:text-white-800 font-semibold text-m text-white-800 px-4 py-2.5 text-center inline-flex items-center"
                      }
                      type="button"
                      data-dropdown-toggle="dropdown"
                      onClick={() => setIsOpen7(!isOpen7)}
                    >
                      Tekne Konaklamalı
                      {isOpen7 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
                          />
                        </svg>
                      ) : (
                        <svg
                          class="w-4 h-4 ml-2"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          ></path>
                        </svg>
                      )}
                    </button>
                    <div
                      class={
                        !isOpen7
                          ? "hidden bg-white text-base z-50 list-none divide-y divide-black-100 rounded shadow my-4"
                          : "bg-white text-base z-50 list-none divide-y divide-black-100 rounded shadow my-4"
                      }
                    >
                      <div
                        id="mega-menu-full-dropdown"
                        class="mt-1 bg-white border-black-200 shadow-sm border-y dark:bg-black-800 dark:border-black-600"
                      >
                        <a href="/camping4gun3gece">
                          <div class="grid max-w-screen-xl px-4 py-5 mx-auto text-white-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-black-50">
                            <ul aria-labelledby="mega-menu-full-dropdown-button">
                              <li class="font-semibold">
                                4 Gün 3 Gece 2 Aktivite
                              </li>
                            </ul>
                          </div>
                        </a>
                        <a href="/camping5gun4gece">
                          <div class="grid max-w-screen-xl border px-4 py-5 mx-auto text-white-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-black-50">
                            <ul aria-labelledby="mega-menu-full-dropdown-button">
                              <li class="font-semibold">
                                5 Gün 4 Gece 3 Aktivite
                              </li>
                            </ul>
                          </div>
                        </a>
                        <a href="/camping7gun6gece">
                          <div class="grid max-w-screen-xl border px-4 py-5 mx-auto text-white-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-black-50">
                            <ul aria-labelledby="mega-menu-full-dropdown-button">
                              <li class="font-semibold">
                                7 Gün 6 Gece 5 Aktivite
                              </li>
                            </ul>
                          </div>
                        </a>
                      </div>
                    </div>
                  </li>
				   */}
                </ul>
              </div>
            </div>
          </div>
          <a
            href="/camping"
            class="mr-5 hover:text-white-800 font-semibold text-m text-white"
          >
            Camping
          </a>
          <a
            href="/yacthing"
            class="mr-5 hover:text-white-800 font-semibold text-m text-white"
          >
            Yacthing
          </a>
          {/* Kiralık  */}
          <button
            class={
              !isOpen2
                ? "mr-5 hover:text-white font-semibold text-m text-white px-4 py-2.5 text-center inline-flex items-center"
                : "mr-5 hover:text-white font-semibold text-m text-white px-4 py-2.5 text-center inline-flex items-center"
            }
            type="button"
            data-dropdown-toggle="dropdown"
            onClick={() => setIsOpen2(!isOpen2)}
          >
            Kiralık Hizmetlerimiz{" "}
            {isOpen2 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
                />
              </svg>
            ) : (
              <svg
                class="w-4 h-4 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            )}
          </button>
          <div
            class={
              !isOpen2
                ? "hidden bg-white text-base z-50 list-none divide-y divide-gray-100 rounded shadow my-4"
                : "bg-white text-base z-50 list-none divide-y divide-red-100 rounded shadow my-4"
            }
          >
            <div
              id="mega-menu-full-dropdown"
              class="mt-1 bg-white border-gray-200 shadow-sm border-y dark:bg-gray-800 dark:border-gray-600"
            >
              <div class="grid max-w-screen-m px-2 py-3 mx-auto text-gray-900 dark:text-white sm:grid-cols-4 sm:grid-cols-2 md:px-6 mr-40">
                <ul aria-labelledby="dropdown-button">
                  <li className="border">
                    <a class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                      <div class="font-semibold">
                        <button
                          class={
                            !isOpen7
                              ? "mr-5 hover:text-red-800 font-semibold text-m text-gray-800 px-4 py-2.5 text-center inline-flex items-center"
                              : "mr-5 hover:text-red-800 font-semibold text-m text-red-800 px-4 py-2.5 text-center inline-flex items-center"
                          }
                          type="button"
                          data-dropdown-toggle="dropdown"
                          onClick={() => setIsOpen7(!isOpen7)}
                        >
                          Villa Kiralama
                          {isOpen7 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
                              />
                            </svg>
                          ) : (
                            <svg
                              class="w-4 h-4 ml-2"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          )}
                        </button>
                        <div
                          class={
                            !isOpen7
                              ? "hidden bg-white text-base z-50 list-none divide-y divide-gray-100 rounded shadow my-4"
                              : "bg-white text-base z-50 list-none divide-y divide-gray-100 rounded shadow my-4"
                          }
                        >
                          <div
                            id="mega-menu-full-dropdown"
                            class="mt-1 bg-white border-gray-200 shadow-sm border-y dark:bg-gray-800 dark:border-gray-600"
                          >
                            <a href="/villa1">
                              <div class="grid max-w-screen-xl px-4 py-5 mx-auto text-gray-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-gray-50">
                                <ul aria-labelledby="mega-menu-full-dropdown-button">
                                  <li class="font-semibold ">Rüya Villa-1</li>
                                </ul>
                              </div>
                            </a>
                            <a href="/villa2">
                              <div class="grid max-w-screen-xl border px-4 py-5 mx-auto text-gray-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-gray-50">
                                <ul aria-labelledby="mega-menu-full-dropdown-button">
                                  <li class="font-semibold">Rüya Villa-2</li>
                                </ul>
                              </div>
                            </a>
                            <a href="/villa3">
                              <div class="grid max-w-screen-xl border px-4 py-5 mx-auto text-gray-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-gray-50">
                                <ul aria-labelledby="mega-menu-full-dropdown-button">
                                  <li class="font-semibold">Rüya Villa-3</li>
                                </ul>
                              </div>
                            </a>
                            <a href="/villa4">
                              <div class="grid max-w-screen-xl border px-4 py-5 mx-auto text-gray-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-gray-50">
                                <ul aria-labelledby="mega-menu-full-dropdown-button">
                                  <li class="font-semibold">Rüya Villa-4</li>
                                </ul>
                              </div>
                            </a>
                            <a href="/villa5">
                              <div class="grid max-w-screen-xl border px-4 py-5 mx-auto text-gray-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-gray-50">
                                <ul aria-labelledby="mega-menu-full-dropdown-button">
                                  <li class="font-semibold">Rüya Villa-5</li>
                                </ul>
                              </div>
                            </a>
                            <a href="/villa6">
                              <div class="grid max-w-screen-xl border px-4 py-5 mx-auto text-gray-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6 hover:bg-gray-50">
                                <ul aria-labelledby="mega-menu-full-dropdown-button">
                                  <li class="font-semibold">Rüya Villa-6</li>
                                </ul>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/yacthing"
                      class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
                    >
                      <div class="font-semibold">🌟 Yat Kiralama</div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/speedboat"
                      class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
                    >
                      <div class="font-semibold">🌟 Speed Boat Kiralama</div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/shiptour"
                      class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700"
                    >
                      <div class="font-semibold">🌟 Özel Tekne Kiralama</div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Günübirlik  */}
          <button
            class={
              !isOpen
                ? "mr-5 hover:text-white-800 font-semibold text-m text-white px-4 py-2.5 text-center inline-flex items-center"
                : "mr-5 hover:text-white-800 font-semibold text-m text-white px-4 py-2.5 text-center inline-flex items-center"
            }
            type="button"
            data-dropdown-toggle="dropdown"
            onClick={() => setIsOpen(!isOpen)}
          >
            Günübirlik Aktiviteler{" "}
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="m13 5.586l-4.707 4.707a.999.999 0 1 0 1.414 1.414L12 9.414V17a1 1 0 1 0 2 0V9.414l2.293 2.293a.997.997 0 0 0 1.414 0a.999.999 0 0 0 0-1.414L13 5.586z"
                />
              </svg>
            ) : (
              <svg
                class="w-4 h-4 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            )}
          </button>
          <div
            class={
              !isOpen
                ? "hidden bg-white text-base z-50 list-none divide-y divide-black-100 rounded shadow my-4"
                : "bg-white text-base z-50 list-none divide-y divide-black-100 rounded shadow my-4"
            }
          >
            <div
              id="mega-menu-full-dropdown"
              class="mt-1 bg-white border-black-200 shadow-sm border-y dark:bg-black-800 dark:border-black-600"
            >
              <div class="grid max-w-screen-xl px-4 py-5 mx-auto text-white-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6">
                <ul aria-labelledby="mega-menu-full-dropdown-button">
                  <li className="border">
                    <a
                      href="/kelebeklervadisi"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">
                        ⛵ Kelebekler Vadisi Tekne Turu
                      </div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/12Adalarturu"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">⛵ 12 Adalar Tekne Turu</div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/yamacparasutu"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">🪂 Yamaç Paraşütü</div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/safari"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">🚙 Saklıkent Jeep Safari</div>
                    </a>
                  </li>
                </ul>
                <ul>
                  <li className="border">
                    <a
                      href="/diving"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">💦 Tüplü Dalış</div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/rafting"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">🛶 Rafting</div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/atvtour"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">🌟 Atv Safari</div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/horse"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">🐴 At Binme Turu</div>
                    </a>
                  </li>
                </ul>
                <ul class="md:block">
                  <li className="border">
                    <a
                      href="/dalyan"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">🌟 Dalyan Turu</div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/pamukkale"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">🌟 Pamukkale Turu</div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/rhodestour"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">🌟 Rhodes Turu</div>
                    </a>
                  </li>
                  <li className="border">
                    <a
                      href="/speedboat"
                      class="block p-3 rounded-lg hover:bg-black-50 dark:hover:bg-black-700"
                    >
                      <div class="font-semibold">
                        🌟 Hız Motoru (Speed Boat)
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* Mobile Menu  */}
    </header>
  );
};

export default Headerr;
