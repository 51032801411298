import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Slider from "./Slider";
import logo from "../images/logo.svg";
import kamp from "../images/kamp.jpg";
import kamp2 from "../images/kamp2.jpg";
import kamp3 from "../images/kamp3.jpg";
import kamp4 from "../images/kamp4.jpg";
import kamp5 from "../images/kamp5.jpg";
import kamp6 from "../images/kamp6.jpg";
import kamp7 from "../images/kamp7.jpg";
import kamp8 from "../images/kamp8.jpg";
import kamp9 from "../images/kamp9.jpg";

const Camping = () => {
  const images = [
    {
      url: kamp9,
    },
    {
      url: kamp8,
    },
    {
      url: kamp6,
    },
    {
      url: kamp7,
    },
    {
      url: kamp,
    },
    {
      url: kamp2,
    },
    {
      url: kamp3,
    },
    {
      url: kamp4,
    },
    {
      url: kamp5,
    },
  ];
  return (
    <>
      <Header />
      <div class="mt-5 text-center">
        <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
          CAMPING
        </h1>
      </div>
      <Slider slides={images} />
      <main class="p-5">
        <div class="text-center">
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto font-bold">
            Fethiye Ölüdeniz mevkisinde bulunan kamp alanımız 10000 m2 alan
            üzerine kuruludur. Glambing çadır alanı, karavan park alanı, yoga
            inziva alanı, bungolovları, aileler için havuzlu bungolovları,
            restoran ve ortak kullanım alanları ile hizmetinizdedir. Tercihe
            göre kendi çadırınızı getirebilir dilerseniz tesisimizde hazır
            bulunan çadır, glambing, bungolav ve havuzlu bungolavlarda konaklama
            yapabilirsiniz. Kahvaltı ve yemekler günün her saati misafirlerimiz
            için uygun fiyattan sunulmaktadır. Havuzlu bungolavlarımız aileler
            için duş ve wc içindedir.
            <h2 class="sm:text-3xl text-2xl font-bold text-center title-font text-gray-900 mb-4 mt-5">
              Ekstralar
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Kahvaltı ve Yemekler
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Aktiviteler
              </li>

              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Ölüdeniz Servis
              </li>
            </ul>
            <div>
              <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
                Ücretlendirme (Gecelik) :
              </h2>
              <ul class="list-disc list-inside font-bold text-xl ">
                <li class="flex items-center mb-2">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Kendi Çadırı: 300 TL
                </li>
                <li class="flex items-center mb-2">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Tesis Çadırı: 400 TL
                </li>
                <li class="flex items-center mb-2">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Glambing: 550 TL
                </li>
                <li class="flex items-center mb-2">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Bungolov: 1000 TL
                </li>
                <li class="flex items-center mb-2">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Havuzlu Bungolov: 1250 TL
                </li>
                <li class="flex items-center mb-2">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Kabin House: 700 TL
                </li>
                <p class="text-red-600 font-bold mb-3 flex items-center mt-5">
                  - Temmuz ve Ağustos Aylarında Fiyatlarımız Farklılık
                  Gösterebilir, Lütfen Bilgi Alınız.
                </p>
              </ul>
            </div>
          </p>
          <div class="text-center">
            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
                🌟 REZERVASYON VE DETAYLI BİLGİ İÇİN:
              </h2>
              <ul class="max-w-md space-y-1 list-inside font-bold text-xl">
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  İLETİŞİM ( +90 552 390 30 20 )
                </li>
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  İLETİŞİM ( +90 507 539 29 61 )
                </li>
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  WHATSAPP ( +90 507 539 29 61 )
                </li>
              </ul>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Camping;
