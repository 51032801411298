import React from "react";
import Packages from "./Packages";
import Todays from "./Todays";
import diving from "../images/diving2.jpg";
import oludeniz from "../images/oludeniz.jpg";
import oludeniz1 from "../images/oludeniz1.jpg";
import rafting from "../images/rafting7.jpg";
import yat from "../images/yat1.jpg";
import parasut from "../images/parasut.jpg";
import kamp from "../images/kamp9.jpg";
import MainFooter from "./MainFooter";
import UpArrow from "./UpArrow";
import Whatsapp from "./Whatsapp";
import Sliderr from "./Sliderr";
import safari from "../images/safari.jpg";
import horse from "../images/atturu8.jpg";

const Main = () => {
  const images = [
    {
      url: oludeniz1,
    },
    {
      url: parasut,
    },
    {
      url: diving,
    },
    {
      url: rafting,
    },
    {
      url: safari,
    },
    {
      url: horse,
    },

    {
      url: oludeniz,
    },

    {
      url: yat,
    },
    {
      url: kamp,
    },
  ];

  /*
  <Header />
      <Slider slides={images} />
	        <Banner />

  */
  const tarih = "2023-06-10T00:00:00.00Z";
  return (
    <>
      <section>
        <div class="pb-100">
          <Sliderr slides={images} />
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </section>
      <Packages />
      <Todays />
      <UpArrow />
      <Whatsapp />
      <MainFooter />
    </>
  );
};

export default Main;
