import React from "react";
import parasut from "../images/parasut.jpg";
import otel from "../images/otel.jpg";
import otel1 from "../images/otel1.jpg";
import kamp3 from "../images/kamp3.jpg";
import kamp4 from "../images/kamp4.jpg";
import kamp5 from "../images/kamp5.jpg";
import hostel from "../images/hostel.jpg";
import paket1 from "../images/paket-1.jpg";
import paket2 from "../images/paket-2.jpg";
import paket3 from "../images/paket-3.jpg";
import paket4 from "../images/paket-4.jpg";
import paket5 from "../images/paket-5.jpg";
import paket6 from "../images/kelebeklervadisi.jpg";

const Packages = () => {
  return (
    <>
      <section id="turlar" class="padding text-gray-600 body-font">
        {" "}
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col">
            <div class="h-1 bg-gray-200 rounded overflow-hidden">
              <div class="w-24 h-full bg-red-500"></div>
            </div>
            <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
              <h1 class="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">
                PAKET TURLAR
              </h1>
              <p class="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">
                - Farklı Aktiviteler içeren paketlerimiz aşağıdaki gibidir. Göz
                Atabilirsiniz.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
            <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div class="rounded-lg h-64 overflow-hidden">
                <a href="/otel4gun3gece">
                  <img
                    alt="content"
                    class="mx-auto h-full"
                    src={paket1}
                    loading="lazy"
                  />
                </a>
              </div>
              <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
                3 Gece - 4 Gün - 2 Aktivite (Otel){" "}
              </h2>
              <p class="text-base leading-relaxed mt-2">
                🌟 12 Adalar Tekne Turu 🌟Saklıkent Jeep Safari 🌟Fethiye'de 4
                Gün 3 Gece Konaklama 🌟Aktivite Sigortası 🌟Sabah, Öğle ve Akşam
                Yemekleri
              </p>
              <a
                href="/otel4gun3gece"
                class="text-red-500 inline-flex items-center mt-3"
              >
                Daha Fazla Bilgi Al
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
            <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div class="rounded-lg h-64 overflow-hidden">
                <a href="/otel4gun3gece3aktivite">
                  <img
                    alt="content"
                    class="mx-auto h-full"
                    src={paket6}
                    loading="lazy"
                  />
                </a>
              </div>
              <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
                3 Gece - 4 Gün - 3 Aktivite (Otel){" "}
              </h2>
              <p class="text-base leading-relaxed mt-2">
                🌟 Yamaç Paraüşütü 🌟Kelebekler Vadisi Tekne Turu 🌟Saklıkent
                Jeep Safari 🌟Fethiye'de 4 Gün 3 Gece Konaklama 🌟Aktivite
                Sigortası 🌟Sabah, Öğle ve Akşam Yemekleri
              </p>
              <a
                href="/otel4gun3gece3aktivite"
                class="text-red-500 inline-flex items-center mt-3"
              >
                Daha Fazla Bilgi Al
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
            <div class="p-4 md:w-1/3 sm:mb-0">
              <div class="rounded-lg h-64 overflow-hidden">
                <a href="/otel7gun6gece">
                  <img
                    alt="content"
                    class="mx-auto h-full"
                    src={paket2}
                    loading="lazy"
                  />
                </a>
              </div>
              <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
                6 Gece - 7 Gün - 5 Aktivite (Otel){" "}
              </h2>
              <p class="text-base leading-relaxed mt-2">
                🌟 12 Adalar Tekne Turu 🌟 Saklıkent Jeep Safari 🌟 Tüplü Dalış
                🌟 At Binme Turu 🌟 Atv Safari Turu 🌟 Aktivite Sigortası 🌟
                Fethiye de 6 Gece 7 Gün Konaklama 🌟 Sabah ve Akşam Yemekleri 🌟
                Tüm Gün Süren Aktivitelerde Öğle Yemekleri
              </p>
              <a
                href="/otel7gun6gece"
                class="text-red-500 inline-flex items-center mt-3"
              >
                Daha Fazla Bilgi Al
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
          <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
            <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div class="rounded-lg h-64 overflow-hidden">
                <a href="/camping4gun3gece">
                  <img
                    alt="content"
                    class="mx-auto h-full"
                    src={paket3}
                    loading="lazy"
                  />
                </a>
              </div>
              <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
                3 Gece - 4 Gün - 2 Aktivite (Camping){" "}
              </h2>
              <p class="text-base leading-relaxed mt-2">
                🌟 Saklıkent Jeep Safari 🌟 Tüplü Dalış 🌟 Aktivite Sigortası 🌟
                Fethiye de 3 Gece 4 Gün Konaklama 🌟 Sabah Kahvaltısı 🌟
                Aktivite Günlerinde Öğle Yemeği(2. ve 3.gün)
              </p>
              <a
                href="/camping4gun3gece"
                class="text-red-500 inline-flex items-center mt-3"
              >
                Daha Fazla Bilgi Al
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
            <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div class="rounded-lg h-64 overflow-hidden">
                <a href="/camping5gun4gece">
                  <img
                    alt="content"
                    class="mx-auto h-full"
                    src={paket4}
                    loading="lazy"
                  />
                </a>
              </div>
              <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
                4 Gece - 5 Gün - 3 aktivite (Camping)
              </h2>
              <p class="text-base leading-relaxed mt-2">
                🌟 Saklıkent Jeep Safari 🌟 Tüplü Dalış 🌟 Atv Safari 🌟
                Aktivite Sigortası 🌟 Fethiye de 4 Gece 5 Gün Konaklama 🌟 Sabah
                Kahvaltısı 🌟 Aktivite Günlerinde Öğle Yemeği(2. ve 3.gün)
              </p>
              <a
                href="/camping5gun4gece"
                class="text-red-500 inline-flex items-center mt-3"
              >
                Daha Fazla Bilgi Al
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
            <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div class="rounded-lg h-64 overflow-hidden">
                <a href="/camping7gun6gece">
                  <img
                    alt="content"
                    class="mx-auto h-full"
                    src={paket5}
                    loading="lazy"
                  />
                </a>
              </div>
              <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
                6 Gece - 7 Gün - 5 Aktivite (Camping)
              </h2>
              <p class="text-base leading-relaxed mt-2">
                🌟 Saklıkent Jeep Safari 🌟 Tüplü Dalış 🌟 Atv Safari 🌟 AT
                Binme Turu 🌟 12 Adalar Tekne Turu 🌟 Aktivite Sigortası 🌟
                Fethiye de 6 Gece 7 Gün Konaklama 🌟 Sabah Kahvaltısı 🌟
                Aktivite Günlerinde Öğle Yemeği(2. 3. ve 6. gün)
              </p>
              <a
                href="/camping7gun6gece"
                class="text-red-500 inline-flex items-center mt-3"
              >
                Daha Fazla Bilgi Al
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Packages;
