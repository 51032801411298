import "./App.css";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Main from "./components/Main";
import PageContent from "./components/PageContent";
import YamacParasutu from "./components/YamacParasutu";
import Rafting from "./components/Rafting";
import KelebeklerVadisi from "./components/KelebeklerVadisi";
import Safari from "./components/Safari";
import Diving from "./components/Diving";
import Boat from "./components/Boat";
import Dalyan from "./components/Dalyan";
import Horse from "./components/Horse";
import Pamukkale from "./components/Pamukkale";
import TekneTuru from "./components/TekneTuru";
import Rhodos from "./components/Rhodos";
import Atv from "./components/Atv";
import Yat from "./components/Yat";
import Camping from "./components/Camping";
import Campingyedi from "./components/Campingyedi";
import Campingbes from "./components/Campingbes";
import Campingdort from "./components/Campingdort";
import Campingaltı from "./components/Campingaltı";
import Otel3 from "./components/Otel3";
import Otel4 from "./components/Otel4";
import Otel5 from "./components/Otel5";
import Villa1 from "./components/Villa1";
import Villa2 from "./components/Villa2";
import Villa3 from "./components/Villa3";
import Villa4 from "./components/Villa4";
import Villa5 from "./components/Villa5";
import Villa6 from "./components/Villa6";

function App() {
  return (
    <Routes>
      <Route index element={<Main />} />
      <Route path="/12Adalarturu" element={<PageContent />} />
      <Route path="/yamacparasutu" element={<YamacParasutu />} />
      <Route path="/rafting" element={<Rafting />} />
      <Route path="/kelebeklervadisi" element={<KelebeklerVadisi />} />
      <Route path="/safari" element={<Safari />} />
      <Route path="/diving" element={<Diving />} />
      <Route path="/speedboat" element={<Boat />} />
      <Route path="/dalyan" element={<Dalyan />} />
      <Route path="/horse" element={<Horse />} />
      <Route path="/pamukkale" element={<Pamukkale />} />
      <Route path="/shiptour" element={<TekneTuru />} />
      <Route path="/atvtour" element={<Atv />} />
      <Route path="/rhodestour" element={<Rhodos />} />
      <Route path="/yacthing" element={<Yat />} />
      <Route path="/villa" element={<Yat />} />
      <Route path="/camping" element={<Camping />} />
      <Route path="/camping7gun6gece" element={<Campingyedi />} />
      <Route path="/camping5gun4gece" element={<Campingbes />} />
      <Route path="/camping4gun3gece" element={<Campingdort />} />
      <Route path="/otel4gun3gece" element={<Otel3 />} />
      <Route path="/otel4gun3gece3aktivite" element={<Otel4 />} />
      <Route path="/otel7gun6gece" element={<Otel5 />} />
      <Route path="/villa1" element={<Villa1 />} />
      <Route path="/villa2" element={<Villa2 />} />
      <Route path="/villa3" element={<Villa3 />} />
      <Route path="/villa4" element={<Villa4 />} />
      <Route path="/villa5" element={<Villa5 />} />
      <Route path="/villa6" element={<Villa6 />} />
      <Route path="*" element={<h1>404 Not Found</h1>} />
    </Routes>
  );
}

export default App;
