import React from "react";
import logo from "../images/logo.svg";
import logoo from "../images/logooo.png";
import UpArrow from "./UpArrow";
import Whatsapp from "./Whatsapp";

const MainFooter = () => {
  return (
    <footer class="border bg-pink-50 text-gray-600 body-font">
      <div class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
          <a
            href="/"
            class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
          >
            <img src={logoo} width={200} height={70} alt="Logo" />
          </a>
          <p class="mt-2 text-sm text-gray-500">
            Tatil İçin En Doğru Adres.
            <span class="inline-flex sm:ml-auto sm:mt-0 justify-center sm:justify-start">
              <a
                href="https://instagram.com/ruyagibitatil?igshid=YmMyMTA2M2Y="
                class="ml-3 text-gray-500"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
            </span>
          </p>
        </div>
        <div class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
          <div class="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              AKTİVİTELER
            </h2>
            <nav class="list-none mb-10">
              <li>
                <a
                  href="/12Adalarturu"
                  class="text-gray-600 hover:text-gray-800"
                >
                  12 Adalar Turu
                </a>
              </li>
              <li>
                <a
                  href="/yamacparasutu"
                  class="text-gray-600 hover:text-gray-800"
                >
                  Yamaç Paraşütü
                </a>
              </li>
              <li>
                <a href="/rafting" class="text-gray-600 hover:text-gray-800">
                  Rafting
                </a>
              </li>
              <li>
                <a
                  href="/kelebeklervadisi"
                  class="text-gray-600 hover:text-gray-800"
                >
                  Kelebekler Vadisi
                </a>
              </li>
            </nav>
          </div>
          <div class="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              PAKET TURLAR (OTEL)
            </h2>
            <nav class="list-none mb-10">
              <li>
                <a
                  href="/otel4gun3gece"
                  class="text-gray-600 hover:text-gray-800"
                >
                  3 Gece 4 Gün 2 Aktivite
                </a>
              </li>
              <li>
                <a
                  href="/otel4gun3gece3aktivite"
                  class="text-gray-600 hover:text-gray-800"
                >
                  3 Gece 4 Gün 3 Aktivite
                </a>
              </li>
              <li>
                <a
                  href="/otel7gun6gece"
                  class="text-gray-600 hover:text-gray-800"
                >
                  6 Gece 7 Gün 5 Aktivite
                </a>
              </li>
            </nav>
          </div>
          <div class="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              AKTİVİTELER
            </h2>
            <nav class="list-none mb-10">
              <li>
                <a href="/diving" class="text-gray-600 hover:text-gray-800">
                  Tüplü Dalış
                </a>
              </li>
              <li>
                <a href="/speedboat" class="text-gray-600 hover:text-gray-800">
                  Sürat Botu
                </a>
              </li>
              <li>
                <a href="/dalyan" class="text-gray-600 hover:text-gray-800">
                  Dalyan Turu
                </a>
              </li>
              <li>
                <a href="/pamukkale" class="text-gray-600 hover:text-gray-800">
                  Pamukkale Turu
                </a>
              </li>
            </nav>
          </div>
          <div class="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              PAKET TURLAR (KAMP)
            </h2>
            <nav class="list-none mb-10">
              <li>
                <a
                  href="/camping4gun3gece"
                  class="text-gray-600 hover:text-gray-800"
                >
                  3 Gece 4 Gün 2 Aktivite
                </a>
              </li>
              <li>
                <a
                  href="/camping5gun4gece"
                  class="text-gray-600 hover:text-gray-800"
                >
                  4 Gece 5 Gün 3 Aktivite
                </a>
              </li>
              <li>
                <a
                  href="/camping7gun6gece"
                  class="text-gray-600 hover:text-gray-800"
                >
                  6 Gece 7 Gün 5 Aktivite
                </a>
              </li>
            </nav>
          </div>
          <div class="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              AKTİVİTELER
            </h2>
            <nav class="list-none mb-10">
              <li>
                <a href="/horse" class="text-gray-600 hover:text-gray-800">
                  At Gezisi
                </a>
              </li>
              <li>
                <a href="/atvtour" class="text-gray-600 hover:text-gray-800">
                  Atv Safari
                </a>
              </li>
              <li>
                <a href="/safari" class="text-gray-600 hover:text-gray-800">
                  Saklıkent Safari
                </a>
              </li>
              <li>
                <a href="/rhodestour" class="text-gray-600 hover:text-gray-800">
                  Rodos Gezisi
                </a>
              </li>
            </nav>
          </div>
          <div class="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              AKTİVİTELER
            </h2>
            <nav class="list-none mb-10">
              <li>
                <a href="/camping" class="text-gray-600 hover:text-gray-800">
                  Kamp
                </a>
              </li>
              <li>
                <a href="/shiptour" class="text-gray-600 hover:text-gray-800">
                  Özel Tekne Kiralama
                </a>
              </li>
              <li>
                <a href="/yacthing" class="text-gray-600 hover:text-gray-800">
                  Yat
                </a>
              </li>
              <li>
                <a href="/villa1" class="text-gray-600 hover:text-gray-800">
                  Villa Kiralama
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div class="bg-gray-100">
        <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p class="text-gray-500 text-sm text-center sm:text-left">
            © 2023 RüyaGibiTatil —
            <a
              href="https://twitter.com/knyttneve"
              rel="noopener noreferrer"
              class="text-gray-600 ml-1"
              target="_blank"
            >
              Tüm Hakları Saklıdır
            </a>
          </p>
          <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start"></span>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
