import React from "react";
import parasut from "../images/parasut.jpg";
import kelebekler2 from "../images/kelebekler2.jpg";
import rafting from "../images/rafting1.jpg";
import safari from "../images/safari.jpg";
import diving from "../images/diving1.jpg";
import speedboat from "../images/speed1.jpg";
import dalyan from "../images/dalyan1.jpg";
import horse from "../images/atturu1.jpg";
import pamukkale from "../images/pamukkale1.jpg";
import shiptour from "../images/tekne1.jpg";
import atvtour from "../images/atv1.jpg";
import rhodestour from "../images/rodos1.jpg";
import yacthing from "../images/yat1.jpg";
import camping from "../images/kamp1.jpg";

const Todays = () => {
  return (
    <section id="gunubirlik" class="padding text-gray-600 body-font">
      {" "}
      <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col">
          <div class="h-1 bg-gray-200 rounded overflow-hidden">
            <div class="w-24 h-full bg-red-500"></div>
          </div>
          <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
            <h1 class="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">
              Günübirlik Aktiviteler
            </h1>
            <p class="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">
              - Günübirlik Aktiviteler içeren paketlerimiz aşağıdaki gibidir.
              Göz Atabilirsiniz.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/kelebeklervadisi">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={kelebekler2}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              KELEBEKLER VADİSİ TEKNE TURU
            </h2>
            <p class="text-base leading-relaxed mt-2">
              Türkiye’nin en Meşhur Tekne Turu Olan Kelebekler Vadisi Tekne
              Turunu Tüm Yaz Boyunca Haftanın Her Günü Yapıyoruz. Ölüdeniz’in
              Masmavi Berrak Sularına ev Sahipliği Yapan Muhteşem Koylarını
              Geziyoruz.
            </p>
            <a
              href="/kelebeklervadisi"
              class="text-red-500 inline-flex items-center mt-3"
            >
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/yamacparasutu">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={parasut}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              YAMAÇ PARAŞÜTÜ{" "}
            </h2>
            <p class="text-base leading-relaxed mt-2">
              Hava Sporları Dalında Tüm Dünyanın Bildiği Fethiye Babadağ ‘ da
              Uzun Yıllardır Yapılmakta Olan Yamaç Paraşütü, Eşsiz Blue Logoon
              Manzarası ile 7’den 70′ e Herkezin Deneyimlemek İstediği Bir
              Extrem Spordur.
            </p>
            <a
              href="/yamacparasutu"
              class="text-red-500 inline-flex items-center mt-3"
            >
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/safari">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={safari}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              JEEP SAFARİ
            </h2>
            <p class="text-base leading-relaxed mt-2">
              Jeep Safari Turumuza Sabah 7:30’da ; Sizi Safari Araçlarımızla,
              Bulunduğunuz Yerden Alarak Başlıyoruz. 6 ayrı Yeri Gezmek Üzere,
              Profosyonel Go-Kart’lı Rehber Eşliğinde Yola Koyuluyoruz.
            </p>
            <a
              href="/safari"
              class="text-red-500 inline-flex items-center mt-3"
            >
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
        <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/12Adalarturu">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={shiptour}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              12 ADALAR TEKNE TURU
            </h2>
            <p class="text-base leading-relaxed mt-2">
              Fethiye Merkez Kordon Körfez Açıklarında Bulunan Eşsiz Koylara Bir
              Deniz Yolculuğuna Çıkaracak Olan Teknelerimizle Siz Değerli
              Misafirlerimize Bu Deneyimi Yaşatmak İstiyoruz. Teknelerimiz
              Fethiye Kordondan Her Sabah 11:00 da Haraket Etmekte Olup Akşam
              17:30’da Geri Dönmektedir.
            </p>
            <a
              href="/12Adalarturu"
              class="text-red-500 inline-flex items-center mt-3"
            >
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/diving">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={diving}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              TÜPLÜ DALIŞ
            </h2>
            <p class="text-base leading-relaxed mt-2">
              Fethiye’nin Masmavi Berrak Sularında Yapmış Olduğumuz Tüplü Dalış,
              Su Altı Dünyasını Keşfetmek İsteyenler İçin Harika Bir Fırsat.
              Profosyonel Dalış Hocalarımızla Yapacak Olduğunuz Bu Güzel
              Aktivite İçin Daha Önce Dalmış Olup Olmadığınızın Hatta Yüzme
              Biliyor Olup Olmamanızın Bir Önemi Yoktur.
            </p>
            <a
              href="/diving"
              class="text-red-500 inline-flex items-center mt-3"
            >
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/rafting">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={rafting}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              RAFTİNG
            </h2>
            <p class="text-base leading-relaxed mt-2">
              Fethiye Dalaman Çayının Hırçın Sularında Adrenalin Dolu Bir
              Maceraya Hazır Mısınız? Belirttiğiniz Noktalardan Transferinizi
              Sağladıktan Sonra Rafting Bölgesine Varıyoruz ve Hep Birlikte
              Kahvaltı Yapıyoruz. Zengin Bir Kahvaltı Menüsünün Yanısıra
              Sınırsız Çay ve Kahve İçebiliyoruz.
            </p>
            <a
              href="/rafting"
              class="text-red-500 inline-flex items-center mt-3"
            >
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/speedboat">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={speedboat}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              HIZ MOTORU KİRALAMA
            </h2>
            <p class="text-base leading-relaxed mt-2">
              Speed Boat Kiralayacağınız sürat teknesi ile deniz üzerinde size
              özel bir gün geçirebilir,bölgemizin muhteşem koylarını ve
              adalarını keşfedebilirsiniz. Size özel tahsis edilecek olan
              Kaptan, bölgeye hakim ve size tüm bilgiyi verebilecek bilgiye
              sahiptir.
            </p>
            <a class="text-red-500 inline-flex items-center mt-3">
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/dalyan">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={dalyan}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              DALYAN TURU
            </h2>
            <p class="text-base leading-relaxed mt-2">
              Sabah saatlerinde başlayan Dalyan Turu’muzdaki yolculuğumuz Kral
              Mezarlıkları’nın gölgesindeki Dalyan’da sona erdiğinde, teknelere
              geçip sazlıkların arasından kıvrılan Dalyan nehrinde, sizi rüya
              gibi bir gezintiye çıkaracağız..
            </p>
            <a
              href="/dalyan"
              class="text-red-500 inline-flex items-center mt-3"
            >
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/horse">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={horse}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              AT TURU
            </h2>
            <p class="text-base leading-relaxed mt-2">
              At Turu, Çalış Sahilinde Sıra Dışı At Sürme Keyfine Var Mısınız ?
              Atınızı Denizin İçinde Sürebilir, Gün Batımını İzleyebilirsiniz.
              Turumuz Yemyeşil Ağaçları ile Örtülü Ormandan Başlayıp, Derenin
              İçinden Geçerek Çalış Sahilinde Sonlanır.
            </p>
            <a href="/horse" class="text-red-500 inline-flex items-center mt-3">
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/pamukkale">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={pamukkale}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              PAMUKKALE TURU
            </h2>
            <p class="text-base leading-relaxed mt-2">
              Fethiye Pamukkale Turu, benzersiz içeriği ile eminiz ki farklı
              turizm fikirlerine ve beğenilerine sahip her misafirimizin oldukça
              hoşuna gidecektir. Çünkü bu tur kapsamında doğa, tarih, kültür ve
              sağlık dolu termal sular ve çamurlar bir araya gelmektedir.
            </p>
            <a
              href="/pamukkale"
              class="text-red-500 inline-flex items-center mt-3"
            >
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/atvtour">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={atvtour}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              ATV SAFARİ
            </h2>
            <p class="text-base leading-relaxed mt-2">
              Özel Tekne Turu ile rotayı sizin belirlediğiniz bu turda
              istediğiniz durakta fazla zaman geçirip, istediğiniz durağa
              uğramayabilirsiniz. İster ailenizle yalnız siz olarak keyfini
              çıkarabilir yada doğum günü ve benzeri özel günlerinizde
              kullanabilirsiniz.
            </p>
            <a
              href="/atvtour"
              class="text-red-500 inline-flex items-center mt-3"
            >
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div class="rounded-lg h-64 overflow-hidden">
              <a href="/rhodestour">
                <img
                  alt="content"
                  class="object-cover object-center h-full w-full"
                  src={rhodestour}
                  loading="lazy"
                />
              </a>
            </div>
            <h2 class="text-xl font-medium title-font text-gray-900 mt-5">
              RHODES TURU
            </h2>
            <p class="text-base leading-relaxed mt-2">
              Özel Tekne Turu ile rotayı sizin belirlediğiniz bu turda
              istediğiniz durakta fazla zaman geçirip, istediğiniz durağa
              uğramayabilirsiniz. İster ailenizle yalnız siz olarak keyfini
              çıkarabilir yada doğum günü ve benzeri özel günlerinizde
              kullanabilirsiniz.
            </p>
            <a
              href="/rhodestour"
              class="text-red-500 inline-flex items-center mt-3"
            >
              Daha Fazla Bilgi Al
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Todays;
