import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Slider from "./Slider";
import pamukkale from "../images/pamukkale.jpg";
import pamukkale1 from "../images/pamukkale1.jpg";
import pamukkale2 from "../images/pamukkale2.jpg";
import pamukkale3 from "../images/pamukkale3.jpg";
import pamukkale4 from "../images/pamukkale4.jpg";
import pamukkale5 from "../images/pamukkale5.jpg";
import pamukkale6 from "../images/pamukkale6.jpg";

const images = [
  {
    url: pamukkale,
  },
  {
    url: pamukkale1,
  },
  {
    url: pamukkale2,
  },
  {
    url: pamukkale3,
  },
  {
    url: pamukkale4,
  },
  {
    url: pamukkale5,
  },
  {
    url: pamukkale6,
  },
];

const Pamukkale = () => {
  return (
    <>
      <Header />
      <div class="mt-5 text-center">
        <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
          PAMUKKALE TURU
        </h1>
      </div>
      <Slider slides={images} />
      <main class="p-5">
        <div class="text-center">
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto font-bold">
            Fethiye Pamukkale Turu, benzersiz içeriği ile eminiz ki farklı
            turizm fikirlerine ve beğenilerine sahip her misafirimizin oldukça
            hoşuna gidecektir. Çünkü bu tur kapsamında doğa, tarih, kültür ve
            sağlık dolu termal sular ve çamurlar bir araya gelmektedir. Bir
            diğer deyişle, beklentiniz ne olursa olsun karşılanacaktır. Bunun
            dışında gezimizle size konfor ve hız da vaat ediyoruz: Ulaşımımız
            son derece modern, rahat ve tam donanımlı tur otobüsümüz ile
            yapılacaktır. Yani yollarda vakit kaybetmek ve rahatsızlık çekmek
            gibi can sıkıcı ulaşım problemleriyle karşılaşmayacaksınız. Ayrıca
            özellikle öğrenmeyi seven gezginlerdenseniz sizi sevindirecek bir
            haberimiz daha var: Turumuzda profesyonel ve lisanslı tur rehberimiz
            de bize eşlik ediyor olacak. Onun bilgi birikiminden doyasıya
            faydalanırken eğlenceli ve şaşırtıcı hikayeler de öğreneceksiniz.
            Tabii ki tüm bu fırsatların yanı sıra, nefis yerler de göreceğiz.
            Hazırsanız, onlardan da bahsetmek isteriz. Pamukkale: Beyazlar
            İçinde Bir UNESCO Mirası Tepeye tırmanan merdiven basamakları gibi
            görünen Pamukkale travertenleri, Pamukkale’nin en meşhur, en
            görkemli parçalarıdır. İçleri masmavi sular ve beyaz çamurlarla
            kaplı olan bu travertenlerin doğal yollarla nasıl oluştuğunu öğrenip
            onun köklü tarihini incelerken çıplak ayaklarımızın termal sularla
            buluşmasını hissedeceğiz. Bu rahatlatıcı adımlar eşliğinde de
            zirveye doğru bol manzaralı bir yolculuk yapacağız. Gözleri alan
            beyazlık, doğal şifalı sıcacık suları ve özel oluşumu ile UNESCO
            Dünya Mirası listesine adını yazdıran Pamukkale’yi doyasıya
            gezeceğiz. Hierapolis Antik Kenti: Eski Bir Taç Zirvede, bizi
            bambaşka bir atmosfer bekliyor olacak: Bej renklerine ve eski bir
            havaya sahip olan zirvede, Hierapolis Antik Kenti ile tanışacağız.
            Bu kentin Roma ve Bizans dönemini yansıtan çizgileri, enteresan
            sütunları, duvarları, mabetleri, kiliseleri, mezarları, odacıkları,
            sunak taşları ve çok daha fazlası ile bezeli geniş sınırlarını
            gezeceğiz. Üstelik yine rehberimiz müthiş bilgilerle sizi
            aydınlatacak ve kültürel birikiminizi genişletebileceğiniz şaşırtıcı
            bilgiler sunacaklar. Yine Hierapolis’in dünya ve özellikle
            Hristiyanlık çapındaki önemi ile ilgili pek çok bilgi ile
            donanırken, onun tozlu yollarını adımlama keyfine erişeceğiz.
            Kısacası, tarihi bir yürüyüş yapacağız bu Kutsal Şehir’de. Bize
            katılın.
            <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-5">
              Tur Bilgileri
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                LOKASYON - FETHİYE
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                BAŞLANGIÇ - 06:00
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                BİTİŞ - 18:00
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                DAHİL OLANLAR:
              </li>
              <li>
                Transfer - Rehberlik Hizmeti - Sabah Kahvaltısı - Öğle Yemeği
              </li>
              <li>Pamukkale Giriş Ücreti - Sigorta</li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                İLETİŞİM ( +90 552 390 30 20 ){" "}
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                WHATSAPP ( +90 507 539 29 61 )
              </li>
            </ul>
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Pamukkale;
