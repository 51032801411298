import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Slider from "./Slider";
import adalar1 from "../images/12adalar1.jpg";
import adalar2 from "../images/12adalar2.jpg";
import adalar3 from "../images/12adalar3.jpg";
import adalar4 from "../images/12adalar4.jpg";
import adalar5 from "../images/12adalar5.jpg";
import adalar6 from "../images/12adalar6.jpg";

const images = [
  {
    url: adalar5,
  },
  {
    url: adalar6,
  },
  {
    url: adalar1,
  },
  {
    url: adalar2,
  },
  {
    url: adalar3,
  },
  {
    url: adalar4,
  },
];

const PageContent = () => {
  return (
    <>
      <Header />
      <div class="mt-5 text-center">
        <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
          12 ADALAR TEKNE TURU
        </h1>
      </div>
      <Slider slides={images} />
      <main class="p-5">
        <div class="text-center">
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto font-bold">
            Fethiye Merkez Kordon Körfez Açıklarında Bulunan Eşsiz Koylara
            Büyüleyici Bir Deniz Yolculuğuna Çıkaracak Olan Konforlu ve Ferah
            Teknelerimizle Siz Değerli Misafirlerimize Bu Güzel Deneyimi
            Yaşatmak İstiyoruz. Teknelerimiz Fethiye Kordondan Her Sabah 11:00
            da Haraket Etmekte Olup Akşam 17:30’da Geri Dönmektedir. Bu Zaman
            Zarfı İçerisinde Kızılada, Katrancı Koyu, Yassıca Adası, Akvaryum
            Koyu ve Göcek Adasını Geziyoruz. Koyların Her Birinde Kaptanımız
            Sizi Karaya İndiriyor ve Kıyıda Yüzmenizi Sağlıyor. Bu Yüzme
            Bilmeyenler İçin Büyük Bir Avantaj. Bütün Koylarda Yaklaşık 1’er
            Saat Yüzme Molası Veriyoruz. Öğle Yemeğinde Teknede Mangal Yapıyoruz
            ve Gerçekten Bu Konuda İddaliyız. Çünkü Herkezin Bildiği Üzere
            Yüzmek Acıktırır. Bir Çok Aktivitede Olduğu Gibi Bu Turda da Alış
            Bırakış Transferini Sağlıyoruz. Gerek Kafa Dinlemek Gerekse Eğlenmek
            Yada Hem Kafa Dinlemek Hem de Eğlenmek İsteyenler İçin Tekne
            Alternatiflerimiz Mümkündür. Sizde Bu Açık Körfezin Tertemiz
            Sularında Serinlemek İsteseniz Bu Güzel Tura Katılmanız Yeterli..
            <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-5">
              Tur Bilgileri
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                LOKASYON - FETHİYE
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                BAŞLANGIÇ - 10.30
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                BİTİŞ - 17.30
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                DAHİL OLANLAR:
              </li>
              <li> Transfer - Öğle Yemeği - Sigorta</li>
            </ul>
          </p>
          <div class="text-center">
            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              <div>
                <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
                  Ücretlendirme (Tek Kişi) :
                </h2>
                <ul class="list-disc list-inside font-bold text-xl ">
                  <li class="flex items-center mb-2">
                    <svg
                      class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    12 Adalar Tekne Turu: 600 TL
                  </li>
                  <p class="text-red-600 text-sm font-bold mb-3 flex items-center mt-5">
                    - Temmuz ve Ağustos Aylarında Fiyatlarımız Farklılık
                    Gösterebilir, Lütfen Bilgi Alınız.
                  </p>
                </ul>
              </div>
              <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
                🌟 REZERVASYON VE DETAYLI BİLGİ İÇİN:
              </h2>
              <ul class="max-w-md space-y-1 list-inside font-bold text-xl">
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  İLETİŞİM ( +90 552 390 30 20 )
                </li>
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  İLETİŞİM ( +90 507 539 29 61 )
                </li>
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  WHATSAPP ( +90 507 539 29 61 )
                </li>
              </ul>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PageContent;
