import React from "react";
// This is the way to import an SVG file and then pass it as a props
import { ReactComponent as CompanyIcon } from "../images/logo.svg";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";

const Whatsapp = () => {
  return (
    <WhatsAppWidget
      CompanyIcon={CompanyIcon}
      sendButtonText="Mesaj Gönder"
      inputPlaceHolder="Merhaba Bilgi Almak İstiyorum"
      phoneNumber="+905075392961"
      message="Merhaba! 👋🏼 ,
	  Size nasıl yardımcı olabiliriz?"
      companyName="Rüya Gibi Tatil"
      replyTimeText=""
    />
  );
};

export default Whatsapp;
