import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Slider from "./Slider";
import dalyan from "../images/dalyan.jpg";
import dalyan1 from "../images/dalyan1.jpg";
import dalyan2 from "../images/dalyan2.jpg";
import dalyan3 from "../images/dalyan3.jpg";
import dalyan4 from "../images/dalyan4.jpg";

const images = [
  {
    url: dalyan,
  },
  {
    url: dalyan1,
  },
  {
    url: dalyan2,
  },
  {
    url: dalyan3,
  },
  {
    url: dalyan4,
  },
];

const Dalyan = () => {
  return (
    <>
      <Header />
      <div class="mt-5 text-center">
        <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
          DALYAN TURU
        </h1>
      </div>
      <Slider slides={images} />
      <main class="p-5">
        <div class="text-center">
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto font-bold">
            Tatlı ve tuzlu suyun birleştiği doğa harikası. Çamur banyosu, plaj
            ve tarih bir arada. Sabah saatlerinde başlayan Dalyan Turu’muzdaki
            yolculuğumuz Kral Mezarlıkları’nın gölgesindeki Dalyan’da sona
            erdiğinde, teknelere geçip sazlıkların arasından kıvrılan Dalyan
            nehrinde, sizi rüya gibi bir gezintiye çıkaracağız. Doğanın büyülü
            labirentinde ilerledikten sonra Dalyan’ın meşhur çamur banyosunun
            olduğu bölgeye ulaşacağız. İnsanın daha iyi ve genç hissetmesini
            sağladığı söylenen sülfirik çamur banyosunun ardından, cilde iyi
            geldiği bilinen kükür kaynaklarının çıktığı doğal havuzlarda
            vücudunuzu yenileyebilir, cildinizi temizleyip rahatlayabilirsiniz.
            Dalyan Turu’nun bu etabının ardından eminiz ki oldukça acıkmış
            hissedeceksiniz. Nehir kıyısında yer alan restoranda açık büfe yemek
            servisimizle yenilendikten sonra, yine tekne yolu ile yapılacak kısa
            bir yolculuğun ardından İztuzu Plajı’na ulaşacağız. Caretta
            Caretta’ların yumurtlama alanı olduğundan akşam 18:00’den sonra
            kapalı olan İztuzu Plajı’nda yüzebilir, adeta ayağınızın altında
            halı varmış hissi yaratan kumlarına uzanıp güneşlenebilirsiniz.
            Şanslıysanız Caretta Caretta’ları yüzerken görebilirsiniz. Dalyan’ın
            büyülü atmosferinde, eşsiz saatler geçireceğiniz Dalyan Turu ile
            sizlere unutlumaz bir deneyim yaşatacağız.
            <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-5">
              Tur Bilgileri
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                LOKASYON - FETHİYE
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                BAŞLANGIÇ - 08:30
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                BİTİŞ - 18:30
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                DAHİL OLANLAR
              </li>
              <li>Tur Rehberliği - Transfer Çamur Banyosu - Giriş Ücreti</li>
              <li>
                Sigorta - Dalyan İztuzu Tekne Turu - Açık Büfe Öğle Yemeği
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                İLETİŞİM ( +90 552 390 30 20 ){" "}
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                WHATSAPP ( +90 507 539 29 61 )
              </li>
            </ul>
          </p>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Dalyan;
