import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Slider from "./Slider";
import atv from "../images/atv.jpg";
import atv1 from "../images/atv1.jpg";
import atv2 from "../images/atv2.jpg";
import atv3 from "../images/atv3.jpg";

const images = [
  {
    url: atv,
  },
  {
    url: atv1,
  },
  {
    url: atv2,
  },
  {
    url: atv3,
  },
];

const Atv = () => {
  return (
    <>
      <Header />
      <div class="mt-5 text-center">
        <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
          ATV SAFARİ TURU
        </h1>
      </div>
      <Slider slides={images} />
      <main class="p-5">
        <div class="text-center">
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto font-bold">
            Fethiye atv safari turunda, tarihi Kayaköy’de tozlu ve çamurlu
            patika yollarda macera ve adrenalin dolu bir gün sizi bekliyor.
            Ehliyet ve Tecrübe gerektirmeyen bu turda motorlar 4 tekerlekli ve
            otomatik vitestir. Otellerinizden servisimizle alındıktan sonra tur
            başlangıç noktası olan özel parkurumuza varılıyor. Atv safari turu
            Kaya köy mevkinde ki ormanlık alanda bulunan bölgenin en büyük doğal
            parkurunda gerçekleşmektedir. Burada ki kısa bir eğitim sürüşünden
            sonra extreme dolu macera başlıyor. Atv safari turunda isterseniz
            tek motora 2 kişi yada tek kişi olarak motorları sürebiliyorsunuz.
            Motor ekipmanları ve otelden servis tur ücretine dahildir. Siz
            konuklarımız tur bitiminden sonra yine otellerinize servis
            aracımızla bırakılarak macera dolu turunuz sonlanıyor. Bu tur için
            toplam 3 saat zaman ayırmanız yeterlidir. Gün içerisinde üç farklı
            zamanda düzenlenen turumuza hangi zaman dilimi size uygunsa o saati
            tercih ederek rezervasyon yaptırabiliyorsunuz. 12 yaşından
            küçüklerin ve rutin rahatsızlığı olanların bu tura katılmasını
            tavsiye etmiyoruz. TUR DETAYLARI Tur isim: Atv Safari Kayaköy Tur
            Günleri: Her Gün Yaş Limiti : 12+ Servis Bölgeleri:
            Ölüdeniz,Çalış,Hisarönü, Fethiye Aktivite Toplam Süre: 3 Saat #Dahil
            Olanlar: - Aktivite Sigortası - Tur Rehberliği - Transfer - Motor
            Sürüş Ekipmanlar #Dahil Olmayanlar: - Fotograf ve Video - İçecekler
            - Kişisel Harcamalar{" "}
          </p>
          <h4 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-5">
            ENGLISH
          </h4>
          <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto font-bold">
            QUAD BİKE TOUR İNFORMATİON Take one of the quad bikes and follow us.
            We will guide you to some nice but dust and dirt-full places! Yes,
            it will be a dirty day on the quad. But we guarantee you that you
            will have a day full of action. Make sure that you dress for mud and
            wild paths. Unfortunately there is an age limit of 17 for this
            activity. But for those who will follow us will have so much fun.
            The bikes are easy to handle and we do not make speed. We travel
            with them for fun and sightseeing. You don’t need a special license
            or experience for quad bikes! TOUR DETAILS Tour Name: QUAD SAFARI
            Available From: May - 1st Age Limit: 12+ Available Days: EVERYDAY
            Transfer From: Fethiye, Calis, Oludeniz, Hisaronu Price Includes:
            Transport, Insurance Price Excludes: Snack, Drinks Bring Along:
            Shoes, Sun cream, Hat, Camera Duration: Half Day
            <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-5 ">
              Tur Bilgileri / (English is below)
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400 font-bold">
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                LOKASYON - FETHİYE KAYAKÖY
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                TUR GÜNLERİ: Her Gün
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                YAŞ LİMİTİ : 12+
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                SERVİS BÖLGELERİ: Ölüdeniz,Çalış,Hisarönü, Fethiye
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                DAHİL OLANLAR
              </li>
              <li>Aktivite Sigortası - Tur Rehberliği</li>
              <li>Transfer - Motor Sürüş - Ekipmanlar</li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                DAHİL OLMAYANLAR:
              </li>
              <li>- Fotograf ve Video - İçecekler - Kişisel Harcamalar</li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                İLETİŞİM ( +90 552 390 30 20 ){" "}
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                WHATSAPP ( +90 507 539 29 61 )
              </li>
            </ul>
            <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-5">
              TOUR DETAILS
            </h2>
            <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400 font-bold">
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Available From: May - 1st
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Available Days: EVERYDAY
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Age Limit: 12+
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Transfer From: Fethiye, Calis, Oludeniz, Hisaronu
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Price Includes: Transport, Insurance
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Price Excludes: Snack, Drinks
              </li>
              <li>-Bring Along: Shoes, Sun cream, Hat, Camera</li>
              <li>Duration: Half Day</li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                CONTACT ( +90 552 390 30 20 ){" "}
              </li>
              <li class="flex items-center">
                <svg
                  class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                WHATSAPP ( +90 507 539 29 61 )
              </li>
            </ul>
          </p>
          <div class="text-center">
            <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              <div>
                <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
                  Ücretlendirme:
                </h2>
                <ul class="list-disc list-inside font-bold text-xl ">
                  <li class="flex items-center mb-2">
                    <svg
                      class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    Atv Safari: 600 TL (Single) Atv Safari: 750 TL (Double)
                  </li>
                  <p class="text-red-600 text-sm font-bold mb-3 flex items-center mt-5">
                    - Temmuz ve Ağustos Aylarında Fiyatlarımız Farklılık
                    Gösterebilir, Lütfen Bilgi Alınız.
                  </p>
                </ul>
              </div>
              <h2 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4 mt-10">
                🌟 REZERVASYON VE DETAYLI BİLGİ İÇİN:
              </h2>
              <ul class="max-w-md space-y-1 list-inside font-bold text-xl">
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  İLETİŞİM ( +90 552 390 30 20 )
                </li>
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  İLETİŞİM ( +90 507 539 29 61 )
                </li>
                <li class="flex items-center">
                  <svg
                    class="w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  WHATSAPP ( +90 507 539 29 61 )
                </li>
              </ul>
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Atv;
